package com.aestynstudio.website.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaMoon
import com.varabyte.kobweb.silk.components.icons.fa.FaSun
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.theme.SilkTheme
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.rememberColorMode
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img

val NavHeaderStyle by ComponentStyle.base(extraModifiers = { SmoothColorStyle.toModifier() }) {
    Modifier
        .fillMaxWidth()
        //.height(600.px)
        // Intentionally invert the header colors from the rest of the page
        //.backgroundColor(colorMode.toSilkPalette().color)
        .backgroundColor(Colors.White)
        .padding(8.px)
}

val NavItemStyle by ComponentStyle {
    // Intentionally invert the header colors from the rest of the page
    //val linkColor = colorMode.toSilkPalette().background
    val linkColor = Colors.White

    base { Modifier.margin(leftRight = 15.px) }

    link { Modifier.color(linkColor) }
    visited { Modifier.color(linkColor) }
    hover { Modifier.color(Colors.Aqua)}
}

val NavButtonVariant by NavItemStyle.addVariant {
    base { Modifier.padding(0.px).borderRadius(50.percent) }
}

@Composable
private fun NavLink(path: String, text: String) {
    Link(path, text, NavItemStyle.toModifier(), UndecoratedLinkVariant)
}

@Composable
fun NavHeader() {
    var colorMode by rememberColorMode()
    Box(NavHeaderStyle.toModifier()) {
        Column (
            Modifier.fillMaxWidth()
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.px),
                horizontalArrangement = Arrangement.Center
            ){
                Image(
                    src = "/imgs/studio_aestyn_site_head.svg",
                    desc = "Studio Aestyn",
                    modifier = Modifier
                        .styleModifier {
                            width(25.percent)
                            backgroundColor( Colors.White )
                            border (width = 5.px, color = Colors.Black)
                        }
                )
            }
            Row(
                Modifier.fillMaxSize(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center

            ) {
                Box (
                    modifier = Modifier
                        .styleModifier {
                        backgroundColor( Colors. Black )
                    }
                        .padding(16.px)
                ) {
                    Row () {
                        NavLink("/", "HOME")
                        NavLink("/about", "ABOUT")
                        NavLink("/markdown", "MARKDOWN")
                    }
                }
/*                Spacer()

                Button(
                    onClick = { colorMode = colorMode.opposite() },
                    NavItemStyle.toModifier(NavButtonVariant)
                ) {
                    Box(Modifier.margin(8.px)) {
                        when (colorMode) {
                            ColorMode.LIGHT -> FaMoon()
                            ColorMode.DARK -> FaSun()
                        }
                    }
                }
                Tooltip(ElementTarget.PreviousSibling, "Toggle color mode", placement = PopupPlacement.BottomRight)*/
            }
        }
    }
}